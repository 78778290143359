* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 1px solid #000; */
  /* background: red; */
}

html {
  /* overflow: auto; */
  height: 100%;
}

h2 {
  top: 200px;
}

#root {
  z-index: 100;
}

.list {
  background-color: #f2f6ff;
  border: 3px solid #c5d1ed;
  position: fixed;
  padding: 6px;
  width: 1300;
  height: 200;
  z-index: 10;
}

.parent {
  margin: 40px 0px;
}

#modal {
  background-color: rgba(80, 80, 80, 0.8);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal:hover {
  cursor: pointer;
}

div {
  transition: background-color 0.3s, z-index 0.3s;
}

#modal:empty {
  top: 100;
  background-color: rgba(255, 255, 255, 0);
  /* display: none; */
  z-index: -3;
}

.infoCST p {
  margin: 0px 0px 0px 5px;
}

.infoCST a {
  margin: 0px;
  color: #561651;
  margin-left: 5px;
}

.infoCST a:hover {
  cursor: pointer;
  color: #001a41;
}

#ourValuesHeader {
  color: #561651;
  text-decoration: none;
  font-style: normal;
}

.ourValuesLi {
  margin-left: 15px;
  color: #d09f53;
}

.ourValuesLiText {
  color: #001a41;
}

#logoTransformation {
  width: 60%;
  margin: auto;
}

#logosEvolution {
  margin: 20px 15px;
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
}

@media (max-width: 760px) {
  #logoTransformation {
    width: 90%;
    margin: auto;
    max-height: 100px;
  }

  #logosEvolution {
    margin: 0px;
    width: 100vw;
    max-height: 100px;
  }
}

@media (min-width: 761px) and (max-width: 900px) {
  .footer13 {
    width: 25% !important;
  }

  .infoCST {
    width: 100% !important;
  }

  .event-details p {
    padding: 0px !important;
    margin: 10px 0px 0px -30px !important;
    font-size: 13px !important;
  }

  .event-details h2 {
    padding: 0px !important;
    margin: 20px 0px 0px -30px !important;
    font-size: 20px !important;
  }
}

.cardInfoH2 {
  padding: 15px 0px 0px 15px;
  color: #fff;
  font-size: 1.3em;
  text-align: justify;
  max-width: 50vw;
  word-wrap: break-word;
  margin-left: 70px;
}

.cardInfoP {
  padding: 5px 15px 15px 15px;
  color: #fff;
  font-size: 1.2em;
  text-align: justify;
  max-width: 60vw;
  word-wrap: break-word;
  margin-left: 70px;
}

@media (max-width: 760px) {
  .footer13 {
    width: 50% !important;
  }

  .event-details p {
    padding: 0px !important;
    margin: 20px 0px 0px -100px !important;
    font-size: 10px !important;
  }

  .event-details h2 {
    padding: 0px !important;
    margin: 50px 0px 0px -40px !important;
    font-size: 10px !important;
  }

  .reactFooterColumns {
    padding: 10px;
  }

  .reactFooterColumns p {
    text-align: left;
  }

  .cardInfoP {
    max-width: 70vw;
    margin-left: 0px;
  }

  .cardInfoH2 {
    margin-left: 0px;
    padding-top: 70px;
  }
}

#parentOverhaul {
  margin: 20px 15px;
  height: auto;
  background-color: #561651;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#overhaul {
  height: 100%;
  background-color: #eef1f0;
  padding: 30px;
  border-radius: 20px;
  max-width: 33%;
}

#overhaulP {
  color: #001a41;
  font-size: 30px;
  text-align: left;
  max-width: 100%;
  word-wrap: break-word;
  font-weight: bold;
  margin: 0px;
  line-height: 1;
  padding: 10px 0px;
}

#overhaulText {
  max-height: 100%;
  border-right: 20px;
  max-width: 65%;
  display: inline-block;
}

#overhaulPText {
  color: #fff;
  font-size: 18px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: justify;
  max-width: 100%;
  word-wrap: break-word;
  margin: 0px;
  line-height: 1.1;
  max-height: 100%;
}

@media (max-width: 800px) {
  #parentOverhaul {
    flex-direction: column !important;
  }

  #overhaul {
    width: 100% !important;
    max-width: 100% !important;
    padding: 30px !important;
  }

  #overhaulP {
    font-size: 18px !important;
    padding: 0px;
  }

  #overhaulText {
    width: 100% !important;
    max-width: 100% !important;
    display: block !important;
  }

  #overhaulPText {
    font-size: 15px !important;
    width: 100% !important;
    padding: 20px 20px;
  }
}

.proc-timeline-svg {
  margin: auto;
}

#timelineNotMobile {
  position: relative;
  width: 100vw;
}

.scrollEventsContainer {
  width: 100vw;
  overflow-x: scroll;
  position: absolute;
  top: 47px;
}

#eventsContainer {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

@media screen and (min-width: 1000px) {
  .scrollEventsContainer {
    overflow-x: hidden;
  }

  #eventsContainer {
    overflow-x: hidden;
  }
}


.event-card {
  position: relative;
  z-index: 10;
}

#content-container {
  background-color: #6583a6;
  width: 70vw;
  min-width: 300px;
  margin-top: 20px;
  word-wrap: break-word;
  border-radius: 8px;
  position: relative;
}

#detailedIcon {
  background-color: #561651;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  position: absolute;
  top: -20px;
  left: -20px;
  border: 2px solid #fff;
}

#fadingArrow {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 20%;
  padding: 20px 5px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  background-image: linear-gradient(to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1));
}

@media (max-width: 760px) {
  #detailedIcon {
    left: -10px;
  }
}